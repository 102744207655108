$animation-speed: .55s !default;
$animation-delay: 0s !default;
$animation-left-offset: -60px !default;
$animation-right-offset: 60px !default;
$animation-top-offset: -30px !default;
$animation-bottom-offset: -30px !default;



@media only screen and (max-width: 1920px) {


    .animation-from-left {
        opacity: 0;
        @include translate3d(($animation-left-offset, 0, 0));
        @include multipleTransition((transform $animation-speed ease-in-out, opacity $animation-speed linear));
    }


    .animation-from-right {
        opacity: 0;
        @include translate3d(($animation-right-offset, 0, 0));
        @include multipleTransition((transform $animation-speed ease-in-out, opacity $animation-speed linear));
    }


    .animation-from-top {
        opacity: 0;
        @include translate3d((0, $animation-top-offset, 0));
        @include multipleTransition((transform $animation-speed ease-in-out, opacity $animation-speed linear));
    }

    .animation-from-bottom {
        opacity: 0;
        @include translate3d((0, -$animation-bottom-offset, 0));
        @include multipleTransition((transform $animation-speed ease-in-out, opacity $animation-speed linear));
    }

    .animation-fade {
        opacity: 0;
        @include translate3d((0, 0, 0));
        @include multipleTransition((transform $animation-speed ease-in-out, opacity $animation-speed linear));
    }



    // body .animation--active {
    .section--visited [class*=animation] {
        opacity: 1;
        @include translate3d((0, 0, 0));
        @include multipleTransition((transform $animation-speed ease-in-out $animation-delay, opacity $animation-speed linear $animation-delay));

        .app-header & {
            @include multipleTransition((transform $animation-speed ease-in-out, opacity $animation-speed linear));
        }
    }


    .animation-two-columns {
        > [class^="col"] {
            opacity: 0;
            @include translate3d(($animation-right-offset, 0, 0));
            @include multipleTransition((transform $animation-speed ease-in-out, opacity $animation-speed linear));

            &:first-child {
                opacity: 0;
                @include translate3d(($animation-left-offset, 0, 0));
                @include multipleTransition((transform $animation-speed ease-in-out, opacity $animation-speed linear));
            }

        }

        &.animation--active {
            > [class^="col"] {
                opacity: 1;
                @include translate3d((0, 0, 0));
                @include multipleTransition((transform $animation-speed ease-in-out $animation-delay, opacity $animation-speed linear $animation-delay));
            }
        }
    }

}
