
/////////////////////////THEME VARS////////////////////////////////////

@use "sass:color"

// COLORS

// theme
$cp1: #af0b0b
$cp2: #fecc16
$cp3: #936037
$cp4: #af0b0b

// common
$c-black: #111
$c-white: #fff

// background

$bg1: #f8f8f8
$bg2: #f5f5f5
$bg3: #eee
$bg4: $c-black
$loader-bg: #000

// color settings

$c-text: $c-black
$c-subtext: #666
$c-smalltext: #b9b9b9
$c-border: #ddd

=alpha($c, $op: 0.5)
    background: color.adjust($c, $alpha: $op)

=op($c, $op: 0.5)
    color.adjust($c, $alpha: $op)

/////////////////////////TYPOGRAPHY////////////////////////////////////

// FONT basic

$f-family: 'Poppins', sans-serif
$h-family: 'Poppins', sans-serif
$i-family: "Font Awesome 5 Free"

$f-light: 300
$f-normal: 400
$f-bold: 500
$f-bolder: 700
$strong: 600

$f-size: 20
$f-size-s: 16
$f-size-ss: 12
$f-line: 1.4
$f-weight: $f-normal

// BODY settings

$body-f: $f-family
$body-f-color: $c-black
$body-f-size: $f-size
$body-f-weight: $f-weight
$body-line: $f-line

$body-scrollbar: $cp1

$page-max: 1920px
$page-padding: $mg
$content-max: 1300px
$content-padding: 5%
$content-padding-rwd: 3%

$radius: 2px

// HEADINGS

$h-line: 1.4
$h-weight: 700
$h-trans: initial
$h-color: $c-black
$h-strong: $h-weight
$h1: 54
$h1-weight: $h-weight
$h1-line: 1.2
$h2: 34
$h2-weight: $h-weight
$h2-line: 1.2
$h3: 23
$h3-weight: $h-weight
$h3-line: 1.3
$h4: 18
$h5: 16
$h6: 14

$h1-rwd: 34
$h2-rwd: 28
$h3-rwd: 22

// LINKS

$page-link: $cp1
$page-link-hover: $c-black
$page-link-underline: none
$page-link-underline-hover: none

// OTHERS

$sup: 14
$small: 20

/////////////////////////PAGE HEADER////////////////////////////////////

$header-height: 60px
$header-bg: black
$header-index: 99
$header-position: fixed
$body-top: 0

$nav-link-size: 13
$nav-link-weight: 600
$nav-link-transform: uppercase
$nav-link-letter-spacing: 0px
$nav-link-color: white
$nav-link-bg: transparent
$nav-link-padding: 5px 15px
$nav-link-border: 1px solid transparent
$nav-link-bg-hover: $cp1
$nav-link-color-hover: white
$nav-link-border-hover: 1px solid $cp1
$nav-link-bg-active: $nav-link-bg-hover
$nav-link-border-active: $nav-link-border-hover
$nav-link-color-active: $nav-link-color-hover

$nav-burger-bg: $cp1
$nav-burger-color: white
$nav-rwd-bg: white
$nav-rwd-color: black
$nav-rwd-link-color: white
$nav-rwd-link-bg: #222
$nav-rwd-link-color-active: white
$nav-rwd-link-bg-active: $cp1

$nav-contact-f-size: 16
$nav-contact-ico-size: 16
$nav-contact-f-weight: 500
$nav-contact-transform: none
$nav-contact-color: white
$nav-contact-color-hover: $cp1

/////////////////////////NAV SOCIAL////////////////////////////////////

$nav-social-bg: white
$nav-social-color: $c-black
$nav-social-color-hover: $cp1
$nav-social-bg-hover: transparent

/////////////////////////SECTIONS////////////////////////////////////

$s-padding: $mg $page-padding
$s-margin: 0
$s-header-margin: $mg-b
$s-header-strong: 300
$s-cover: #111
$s-cover-light: white
$s-cover-opacity: 0.8

$s-title-color: $c-black
$s-title-size: $h2
$s-title-margin: 0
$s-title-trans: none

$s-text-align: left
$s-sub-size: 15
$s-sub-color: $c-black
$s-sub-margin: 5px 0 0 0
$s-sub-trans: uppercase
$s-sub-letter: 2px

/////////////////////////ARTICLES////////////////////////////////////

$a-padding: $mg-b $page-padding
$a-margin: 0
$a-header-margin: 0 0 $mg-b 0

$a-title-color: $c-black
$a-title-size: 44
$a-title-strong: 400

$a-subtitle-color: $c-black
$a-subtitle-size: 32
$a-subtitle-strong: 400
$a-subtitle-margin: 0

/////////////////////////BUTTONS////////////////////////////////////

$btn-height: 40px
$btn-f-size: 14px
$btn-weight: 400

$btn-large-height: 60px
$btn-large-f-size: 18px
$btn-large-weight: 400

$btn-primary-color: white
$btn-primary-bg: $cp1
$btn-primary-border: $cp1
$btn-primary-color-hover: white
$btn-primary-bg-hover: $c-black
$btn-primary-border-hover: #fff
$btn-primary-weight: 500

$btn-secondary-bg: transparent
$btn-secondary-border: #fff
$btn-secondary-color: #fff
$btn-secondary-bg-hover: $cp1
$btn-secondary-border-hover: $cp1
$btn-secondary-color-hover: #fff
$btn-secondary-weight: 500

$btn-outlinne-bg: transparent
$btn-outlinne-border: $c-black
$btn-outlinne-text: $c-black
$btn-outlinne-bg-hover: transparent
$btn-outlinne-border-hover: $cp1
$btn-outlinne-text-hover: $cp1

/////////////////////////FORMS////////////////////////////////////

$form-bg: #fff
$form-border: 1px solid rgba(0,0,0,.25)
$form-text: $c-black

/////////////////////////OTHER////////////////////////////////////

// modals

$modal-bg: rgb(0 0 0 / 90%)
$modal-border: rgb(0 0 0 / 90%)

/////////////////////////ANIMATIONS////////////////////////////////////

$speed: 0.45s ease
$a-link: opacity 0.25s, background 0.25s, color 0.25s, border 0.25s
