
html
    font-family: sans-serif
    line-height: 1.15
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    font-size: 62.5%
    font-size: 10px
    overflow-x: hidden

body
    padding: 0
    margin: 0
    font-family: $body-f
    color: $c-black
    +rem(22)
    font-weight: $body-f-weight
    line-height: $body-line
    -webkit-font-smoothing: antialiased
    min-height: -webkit-fill-available
    overflow-x: hidden
    background: top center no-repeat

    > .page-container
        padding-bottom: 0
        padding-top: $body-top
        overflow: hidden

::-webkit-scrollbar-track
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
    -webkit-box-shadow: none
    border-radius: 0
    background-color: #F5F5F5

::-webkit-scrollbar
    width: 12px
    background-color: #F5F5F5

::-webkit-scrollbar-thumb
    border-radius: 5px
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)
    // -webkit-box-shadow: none
    background-color: $body-scrollbar

html
    scrollbar-color: $cp1 white
    scrollbar-width: 12px
    // scrollbar-color: rebeccapurple green
    scrollbar-box-shadow: inset 0 0 6px rgba(0,0,0,.3)

img
    width: auto
    height: auto

img[loading]
    height: auto

.page-container
    max-width: none
    margin: 0 auto
    padding:
        left: $page-padding
        right: $page-padding

.content--padding
    padding-left: $content-padding
    padding-right: $content-padding

.content--max
    max-width: $content-max
    margin-left: auto
    margin-right: auto

::selection
    color: white
    background: $cp1

img
    +translateZ(0)

.white
    color: white

.black
    color: $c-black

.cp1
    color: $cp1

.cp2
    color: $cp2

.cp3
    color: $cp3

.cp4
    color: $cp4

.bg1
    background-color: $bg1

.bg2
    background-color: $bg2

.bg3
    background-color: $bg3

.bg4
    background-color: $bg4

.c-subtext
    display: block
    color: $c-subtext

.c-smalltext
    display: block
    color: $c-smalltext

.d-block
    display: block

// .material-icons
//     // +rem(12) !important
//     opacity: 1
//     +multipleTransition((opacity 0.45s ease, background 0.45s ease, color 0.45s ease, border 0.45s ease))

// .fontloading
//     .material-icons
//         // +rem(12) !important
//         opacity: 0

+breakpoint(desktop)
    .content--max
        padding: 0 5%

    .content--padding
        padding-left: 0
        padding-right: 0

+breakpoint(tablet)
    body
        +rem(18)

    .art .art__subtitle
        +rem(20)

    .content--padding
        // padding-left: 3%
        // padding-right: 3%

+breakpoint(mobilewide)

    .content--padding
        padding-left: 0
        padding-right: 0

    html
        font-size: 8px

    .page-container
        padding-left: 20px
        padding-right: 20px

+breakpoint(mobile)
    html
        font-size: 9.2px

// +breakpoint(4k)
// +breakpoint(laptop)
// +breakpoint(desktop)
// +breakpoint(tablet)
// +breakpoint(mobilewide)
// +breakpoint(mobilesmall)
