
    
.full
    width: 100%


.hide
    display: none !important



.border-top-bottom
    border-top: 1px $c-border solid
    border-bottom: 1px $c-border solid


.border--top
    padding-top: $mg
    margin-top: $mg
    border-top: 1px $c-border solid

.border--bottom
    padding-top: $mg
    margin-top: $mg
    border-bottom: 1px $c-border solid




.sep
    color: $cp1


.t-left
    text-align: left

.t-right
    text-align: right

.t-center
    text-align: center

.t-up
    text-transform: uppercase

.f-bold
    font-weight: $f-bold

.wrap
    +flex-wrap(wrap)
    white-space: wrap

.nowrap
    +flex-wrap(nowrap)
    white-space: nowrap


.noselect
    user-select: none
    -webkit-user-select: none




.left
    margin-left: auto

.right
    margin-right: auto


.item--hide
    display: none



.i-24
    +rem(24)

.i-64
    +rem(64)

.i-128
    +rem(128)

.t--highlight
    background: $cp2
    padding: 2px 5px 2px 5px
    display: inline-block
    font-weight: 300
    // color: white
    strong, .cp1, .cp2
        color: white
        font-weight: 700
        text-shadow: 1px 1px 1px rgb(0 0 0 / 63%)

    &.bg1
        background-color: $cp1
        color: white

        strong, .cp1, .cp2
            color: white
            font-weight: 700

    &.bg2
        background-color: $cp2
        color: $c-black

        strong, .cp1, .cp2
            color: $c-black
            font-weight: 700
            text-shadow: 1px 1px 1px rgb(0 0 0 / 63%)


    
    &.bg3
        background-color: $cp4
        color: white
        border-radius: $radius

        strong, .cp1, .cp2
            color: white
            font-weight: 700
            text-shadow: none
        .sep
            color: $cp2
.shadow
    &::after
        content: ""
        border-radius: 5px
        position: absolute
        z-index: -1
        top: 0
        left: 0
        width: 100%
        height: 100%
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3)
        opacity: 0
        pointer-events: none
        -webkit-transition: opacity 0.9s cubic-bezier(0.165, 0.84, 0.44, 1), box-shadow 0.45s ease
        transition: opacity 0.9s cubic-bezier(0.165, 0.84, 0.44, 1), box-shadow 0.45s ease


.f-light
    font-weight: 400
    +rem(30)

.label-price
    position: absolute
    top: 25%
    right: 2%
    +rem(21)
    +rotate(-5)
    font-weight: 300
.label--demo-price
    display: block
    padding: 2px 12px
    margin: 0
    margin-top: 3px
    +rem(32)

+breakpoint(tablet)
    .t--highlight
        padding: 5px 10px
        margin-bottom: 10px

+breakpoint(mobilewide)
    
    .label-price
        //position: static
        margin: 30px 30px
        order: 0
        display: block
        +flex-wrap(wrap)


+breakpoint(mobilesmall)

    .label-price
        //position: static

    .page-title
        .f-light
            +rem(20)
            line-height: 1.2
            display: block
            margin-top: 10px