.page__footer
    background: rgb(255 255 255 / 54%)
    +rem(14)
    padding-bottom: 20px



.copyrights
    +rem(14)
    +align-items(center)
    margin-top: 0
    padding-top: 20px
    padding:
        left: 20px
        right: 20px

    a
        color: $cp1
        font-weight: 400

        &:hover
            text-decoration: underline




// +breakpoint(4k)
// +breakpoint(laptop)
// +breakpoint(desktop)
// +breakpoint(tablet)
// +breakpoint(mobilewide)
// +breakpoint(mobile)
// +breakpoint(mobilesmall)