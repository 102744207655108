
.order-1
    order: 1

.order-2
    order: 2

.article
    text-align: center
    max-width: $content-max
    margin-left: auto
    margin-right: auto

.article__footer
    margin-top: 60px

.m-image
    border-radius: $radius
    opacity: 1
    +multipleTransition(( opacity 1.15s))
    img
        border-radius: $radius

img.lazybg
    min-height: 10px

.figure
    border-radius: 0

.m-image,
.lazyimg,
.lazybg,
.art__figure,
.lazyloaded,
.art__img
    opacity: 1
    +multipleTransition(( opacity 0.75s))

.lazybg,
.lazyimg,
.lazyload
    opacity: 0

// art grids

$col-margin: 8px

=grid10()
    .art
        min-width: calc(100% - #{$col-margin} * 2)

=grid20()
    .art
        min-width: calc(50% - #{$col-margin} * 2)

=grid30()
    .art
        min-width: calc(33.33% - #{$col-margin} * 2)

=grid40()
    .art
        min-width: calc(25% - #{$col-margin} * 2)

=grid50()
    .art
        min-width: calc(20% - #{$col-margin} * 2)

=grid60()
    .art
        min-width: calc(16.66% - #{$col-margin} * 2)

[data-col]
    +flexbox()
    +flex-wrap(wrap)
    +justify-content(center)
    margin-left: -$col-margin
    margin-right: -$col-margin

    .art
        margin: $col-margin
        max-width: calc(50% - #{$col-margin} * 2)
        +flex(1)

[data-col="1"]
    @include grid10()

[data-col="2"]
    @include grid20()

    .art:nth-child(1n + 3)
        // border: 2px #fafafa solid 

[data-col="3"]
    @include grid30()

    .art:nth-child(1n + 4)
        // border: 2px #fafafa solid 

[data-col="4"]
    @include grid40()

    .art:nth-child(1n + 5)
        // border: 2px #fafafa solid 

[data-col="5"]
    @include grid50()

    .art:nth-child(1n + 6)
        // border: 2px #fafafa solid 

[data-col="6"]
    @include grid60()

    .art:nth-child(2n + 7)
        // border: 2px #fafafa solid

=sizeMedium()
    .art__content
        +rem(16)

    .art__title
        +rem(32)

    .art__subtitle
        +rem(24)

[data-col="3"] .art
    +sizeMedium()

// list styyles
// =====================================================================

.art__list
    // +multipleTransition((transform 0.45s ease, width 0.45s ease, height 0.45s, max-width 0.45s, max-height 0.45s, min-width 0.45s, min-height 0.45s, opacity 0.45s, font 0.45s, padding 0.45s, margin 0.45s))
    +justify-content(flex-start)
    margin-bottom: 30px
    .list--pd
        padding-top: 80px
        padding-bottom: 80px

.list--reorder
    .art:nth-child(2n)
        .art__figure
            order: 2

.list--ico
    .art__title
        +rem(28)

.list--art
    .art__header
        text-align: left
        padding-left: 15px

// arykuły full width
// ===============================================================

.art
    position: relative
    // background: #f9f9f9
    background: transparent
    text-align: left
    margin: $a-margin
    padding: $a-padding
    border-radius: $radius
    padding: 0
    margin-bottom: 60px
    margin: 60px 0
    gap: 30px 60px
    +flexbox()
    +align-items(center)
    // +flex-wrap(nowrap)

    .t-left &,
    &.t-left
        text-align: left !important

    &.t-center
        +justify-content(center)
        text-align: center
    // +multipleTransition((transform 0.45s ease, width 0.45s ease, height 0.45s, max-width 0.45s, max-height 0.45s, min-width 0.45s, min-height 0.45s, opacity 0.45s, font 0.45s, padding 0.45s, margin 0.45s))

    &::nth-child(2n)
        .art__figure
            order: 2

.section__content > .art:first-child
    padding-top: 0

.art__figure
    min-height: 100px
    overflow: hidden
    // background: url('../images/placeholder-bg.png') center center no-repeat
    background: center center no-repeat
    border-radius: $radius
    +flexbox()
    +justify-content(center)
    // +multipleTransition((transform 0.45s ease, width 0.45s ease, height 0.45s, max-width 0.45s, max-height 0.45s, min-width 0.45s, min-height 0.45s, opacity 0.45s, font 0.45s, padding 0.45s, margin 0.45s))
    [data-col] &
        max-height: 300px
        min-width: 40%

[data-src-background]
    background-position: center center
    background-repeat: no-repeat
    background-size: cover

    .list--products &
        background-position: top center

.art__figure[data-src-background]
    height: 300px

.art__img
    height: auto
    // max-height: 350px
    min-width: 100px
    min-height: 100px
    border-radius: $radius
    // +multipleTransition((transform 0.45s ease, width 0.45s ease, height 0.45s, max-width 0.45s, max-height 0.45s, min-width 0.45s, min-height 0.45s, opacity 0.45s, font 0.45s, padding 0.45s, margin 0.45s))

    &.img--h350
        max-height: 350px

    &.img--large
        min-width: 600px

.art__title
    +rem($a-title-size)
    font-weight: $a-title-strong
    line-height: 1.1
    // color: $a-title-color

    span
        line-height: 1
        display: inline-block
    // +multipleTransition((transform 0.45s ease, width 0.45s ease, height 0.45s, max-width 0.45s, max-height 0.45s, min-width 0.45s, min-height 0.45s, opacity 0.45s, font 0.45s, padding 0.45s, margin 0.45s))

.art__subtitle
    display: block
    // +rem($a-subtitle-size)
    +rem(24)
    font-weight: $a-subtitle-strong
    margin: $a-subtitle-margin
    line-height: 1.1
    margin-top: 5px
    // letter-spacing: 2px
    // text-transform: uppercase

.art
    margin-top: 30px

.art__header
    position: relative
    margin: $a-header-margin
    margin: 0
    &.header--border
        border-bottom: 4px $cp1 solid
        padding-bottom: 15px

        .section--cp1 &
            border-color: $cp2
    .t-left &
        text-align: left
    // +multipleTransition((transform 0.45s ease, width 0.45s ease, height 0.45s, max-width 0.45s, max-height 0.45s, min-width 0.45s, min-height 0.45s, opacity 0.45s, font 0.45s, padding 0.45s, margin 0.45s))

.art__content
    margin: 0
    // padding: 0 60px
    width: 100%
    +rem(20)
    // +multipleTransition((transform 0.45s ease, width 0.45s ease, height 0.45s, max-width 0.45s, max-height 0.45s, min-width 0.45s, min-height 0.45s, opacity 0.45s, font 0.45s, padding 0.45s, margin 0.45s))

    p:first-child
        margin-top: 0
    p
        margin-bottom: 0

.art__footer
    margin-top: 30px
    margin-bottom: 0
    text-align: left
    display: block

.art__link-art
    overflow: hidden
    color: $c-black

    &:hover
        color: $c-black

// arykuły w kolumnach modyfikacje
// ===============================================================

[data-col]
    .art
        text-align: center
        +justify-content(center)
        +align-items(flex-start)
        +flex-wrap(wrap)
        +flex(0)
        display: block

        .t-left &
            text-align: left

    .art__content
        padding: 15px 0 15px 0

    .art__figure
        width: 100%
        // background: white
        // height: 250px
        +flexbox()
        +justify-content(center)
        +align-items(center)

    .art__footer
        text-align: center

// arykuły stylowanie wielkosci
// ===============================================================

=size20()
    .art
        padding: 30px

    .art__figure
        height: 300px
        height: auto

    [data-src-background]
        height: 250px

    .art__title
        +rem(44)

    .art__content
        padding: 30px 0 0 0
        +rem(20)

    .art__subtitle
        +rem(14)
        margin-top: 10px

=size30()
    .art
        padding: 30px

    .art__figure
        height: 300px
        height: auto

    .art__title
        +rem(40)

    .art__subtitle
        +rem(14)
        margin-top: 6px

    .art__content
        padding: 30px 0 0 0
        +rem(18)

=size40()
    .art
        padding: 20px 20px 30px 20px

    .art__figure
        height: 250px
        height: auto
        max-height: 200px

    .art__title
        +rem(32)

    .art__subtitle
        +rem(12)
        margin-top: 6px

    .art__content
        padding: 20px 0 0 0
        +rem(16)

=size50()
    .art
        padding: 15px 15px 25px 15px

    .art__figure
        height: 200px
        height: auto

    .art__title
        +rem(22)

    .art__subtitle
        +rem(12)
        margin-top: 6px

    .art__content
        padding: 20px 0 0 0
        +rem(16)

=size60()
    .art
        padding: 15px

    .art__figure
        position: relative
        height: auto
        overflow: hidden

    .art__img
        // position: absolute
        // transform-origin: center center
        // max-width: none
        // height: 150px

    .art__title
        +rem(20)

    .art__subtitle
        +rem(12)
        margin-top: 6px

    .art__content
        padding: 20px 0 0 0
        +rem(14)
        line-height: 1.3

=size80()
    .art__content
        line-height: 1.3
    .art__header
        height: 30px
    .art__title
        max-height: 30px
        +rem(14)

// [data-col="2"]
//     @include size20

// [data-col="3"]
//     @include size30

// [data-col="4"]
//     @include size40

// [data-col="5"]
//     @include size50

// [data-col="6"]
//     @include size60

// arykuły stylowanie wyglądu
// ===============================================================

// .art__list:not([data-col])
//     .art
//         &::before
//             position: absolute
//             content: ''
//             top: 0
//             left: 0
//             width: 100%
//             height: 1px
//             background: #ddd

//         &:first-child
//             &::before
//                 display: none

// [data-col]:not([data-style])
//     .art
//         &::before
//             position: absolute
//             content: ''
//             top: 0
//             left: -8px
//             width: 1px
//             height: 100%
//             background: #ddd

//         &:first-child
//             &::before
//                 display: none

[data-style="1"]
    .art
        @extend .shadow
        +multipleTransition((transform 0.45s ease, width 0.45s ease, height 0.45s, max-width 0.45s, max-height 0.45s, min-width 0.45s, min-height 0.45s, font 0.45s, padding 0.45s, margin 0.45s))
        border: 1px #eee solid
        &::after
            opacity: 0

[data-slider="true"][data-col="5"]
    @include grid50()

[data-slider="true"][data-col="4"]
    @include grid40()

[data-slider="true"]
    .art__figure
        max-height: 150px

    // &[data-slider="true"][data-col="3"]
    //     .art__figure
    //         max-height: 155px

    // &[data-slider="true"][data-col="2"]
    //     .art__figure
    //         max-height: 155px

// [data-slider="true"][data-col="2"]
//     @include grid60()

+breakpoint(desktop)

    #s3
        .splide
            .art
                margin: 15px

    .art
        padding-left: 0
        padding-right: 0

    .art__content
        padding-left: 30px
        padding-right: 30px

    .art__figure
        height: auto

    [data-col]
        .art
            +flex(1)
    [data-col="3"]
        .art
            padding: 20px

        // @include size40()

    [data-col="4"]
        .art
            padding: 15px

        // @include size50()

    [data-col="5"]
        @include grid30()
        // @include size40()
        .art
            padding: 10px 10px 20px

    [data-col="6"]
        @include grid30()
        // @include size40()
        .art
            padding: 10px 10px 20px

    [data-slider="true"]
        @include grid40()

    [data-slider="true"]
        .art__figure
            height: 150px

+breakpoint(tablet)

    .art__title
        // +rem(30)

    .art__content,
    .article
        // +rem(17)
        // line-height: 1.3

    .art__img
        max-height: 250px

    .art__img[src="emil/man2.png"]
        max-height: initial

    .art__subtitle
        margin-top: 5px
        // +rem(26)

    .art__title
        line-height: 1

    [data-col="2"]
        // @include size40()
        .art
            padding: 10px 10px 20px

        [data-src-background]
            height: 200px

    [data-col="3"]
        // @include size50()
        .art
            padding: 10px 10px 20px

        .art__btn
            +rem(14)

    [data-col="4"]
        @include grid20()
        // @include size40()
        .art
            padding: 10px 10px 20px

    [data-col="5"]
        @include grid30()
        // @include size50()
        .art
            padding: 10px 10px 20px
            max-width: 50%

    [data-col="6"]
        @include grid30()
        // @include size50()
        .art
            padding: 10px 10px 20px

    [data-slider="true"]
        @include grid30()
        .art
            // padding: 15px 0 15px 0
        // .art__figure
        //     height: 210px
        //     height: auto
        // .art__header
        //     height: 39px
        // .art__title
        //     max-height: 39px
        //     +rem(18)
        //     line-height: 1.1

    [data-slider="true"]
        .art__figure
            height: 160px

    [data-slider="auto"]
        +flex-wrap(nowrap)

    .article
        text-align: justify

        br
            display: none

    .article__footer
        text-align: center
        margin-top: 30px

+breakpoint(mobilewide)

    .list--ico
        .art__header
            text-align: center

    .art
        +flex-wrap(wrap)
        +justify-content(center)

    .art__content
        // padding-top: 30px
        +justify-content(center)
        text-align: center
        padding: 0
        text-align: left

        p
            //+rem(15)

    .art__header
        text-align: left

    .list--reorder .art:nth-child(2n) .art__figure
        order: 0

    .article__footer
        text-align: center
        margin-top: 30px

    [data-col="2"],
    [data-col="3"],
    [data-col="4"],
    [data-col="5"],
    [data-col="6"]
        @include grid20()
        // @include size50()

        .art
            max-width: 100%

        .art__link-art
            +flex-wrap(wrap)

    [data-col="2"]
        .art
            padding: 10px 10px 15px 10px

    [data-slider="true"]
        @include grid20()

    [data-col]
        margin:
            left: 0
            right: 0

+breakpoint(mobile)

    [data-col="2"],
    [data-col="3"],
    [data-col="4"],
    [data-col="5"],
    [data-col="6"]
        @include grid10()
        // @include size60()
    .art__list
        .art
            padding-left: 0
            padding-right: 0

    [data-slider="true"]
        @include grid20()

    .art__title
        +rem(34)

+breakpoint(mobilesmall)
    [data-slider="true"]
        @include grid10()

// +breakpoint(4k)
// +breakpoint(laptop)
// +breakpoint(desktop)
// +breakpoint(tablet)
// +breakpoint(mobilewide)
// +breakpoint(mobile)
// +breakpoint(mobilesmall)
