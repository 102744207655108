// @font-face
//     font-family: 'fontPage'
//     font-style: normal
//     font-weight: 300
//     src: url('../fonts/Roboto300.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto300.woff2') format('woff2')
//     font-display: swap

// @font-face
//     font-family: 'fontPage'
//     font-style: normal
//     font-weight: 400
//     src: url('../fonts/Roboto400.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto400.woff2') format('woff2')
//     font-display: swap

// @font-face
//     font-family: 'fontPage'
//     font-style: normal
//     font-weight: 700
//     src: url('../fonts/Roboto700.eot?#iefix') format('embedded-opentype'), url('../fonts/Roboto700.woff2') format('woff2')
//     font-display: swap

a
    color: $page-link
    text-decoration: $page-link-underline
    +multipleTransition(($a-link))

    &:hover
        color: $page-link-hover
        text-decoration: $page-link-underline-hover

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6
    // margin: 0
    // padding: 0
    font:
        family: $h-family
        weight: $h-weight
    text-transform: $h-trans
    line-height: $h-line
    //color: $h-color

    strong
        &.f-normal
            font-weight: $f-normal

    &.f-normal,
    .f-normal
        font-weight: $f-normal

strong
    font-weight: $strong

sup
    +rem($sup)

small
    // +rem($small)

    sup
        // +rem($small)

h1,.h1
    +rem($h1)
    font-weight: $h1-weight
    line-height: $h1-line

h2,.h2
    +rem($h2)
    font-weight: $h2-weight
    line-height: $h2-line

h3,.h3
    +rem($h3)
    font-weight: $h3-weight
    line-height: $h3-line

h4,.h4
    +rem($h4)

h5,.h5
    +rem($h5)

h6,.h6
    +rem($h6)

+breakpoint(tablet)

    h1,.h1
        +rem($h1-rwd)

    h2,.h2
        +rem($h2-rwd)

    h3,.h3
        +rem($h3-rwd)

+breakpoint(mobilewide)

    h1,.h1
        +rem($h1-rwd)

    h2,.h2
        +rem($h2-rwd)

    h3,.h3
        +rem($h3-rwd)

    h4,.h4
        +rem($h5)

    h5,.h5
        +rem($h6)

    h6,.h6
        +rem($h6)

    h3, h4, h5, h6,
    .h3, .h4, .h5, .h6
        font-weight: 600

// +breakpoint(mobile)

//     h1,.h1
//         +rem($h2)

//     h2,.h2
//         +rem($h3)

//     h3,.h3
//         +rem($h4)

//     h4,.h4
//         +rem($h5)

//     h5,.h5
//         +rem($h5)

//     h6,.h6
//         +rem($h5)

// +breakpoint(4k)
// +breakpoint(laptop)
// +breakpoint(desktop)
// +breakpoint(tablet)
// +breakpoint(mobilewide)
// +breakpoint(mobile)
// +breakpoint(mobilesmall)
