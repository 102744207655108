.section
    position: relative
    background-size: cover
    background-attachment: fixed
    padding: #{$s-padding}
    text-align: center
    min-height: 310px

    &.pd-t-0
        padding-top: 0

    &.section--full
        padding-left: 0
        padding-right: 0

    &.t-left
        text-align: left

    &.t-right
        text-align: right

    &.content--padding
        padding-left: $content-padding
        padding-right: $content-padding

.section:not([class*="mg"])
    margin: $s-margin

.section--header
    @extend .flex-center
    +flex-wrap(wrap)

    .section__header
        margin-bottom: 0

.section__header
    position: relative
    margin-bottom: $s-header-margin
    text-align: center
    z-index: 2

    h2
        font-weight: 300

    strong
        font-weight: $s-header-strong

    &.m0,
    &.mg0
        margin-bottom: 0

.section__title
    margin: $s-title-margin
    color: $s-title-color
    text-transform: $s-title-trans
    +rem($s-title-size)

.section__subtitle
    text-transform: $s-sub-trans
    letter-spacing: $s-sub-letter
    +rem($s-sub-size)
    margin: $s-sub-margin

[data-dark="true"],
[data-cover="dark"]
    position: relative
    color: white
    background: $cp1
    .section__title,
    .section__subtitle,
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p, a:not(.btn, .contact__link)
        color: white

.section__content
    position: relative
    text-align: $s-text-align
    max-width: $content-max
    margin-left: auto
    margin-right: auto
    padding:
        left: $content-padding
        right: $content-padding

    &.pd0
        padding: 0

    &.full
        max-width: none

.contact
    max-width: 100%

[data-cover]::before
    position: absolute
    content: ''
    top: 0
    left: 0
    width: 100%
    height: 100%
    pointer-events: none
    +multipleTransition((opacity $speed))
    z-index: 2

    .body--animation-start &
        opacity: $s-cover-opacity

.section--bg
    z-index: 1
    background-attachment: fixed
    background-position: center center

.section--cp1
    background: $cp1
    color: white

    .cp1
        color: $cp2

.section--cp2
    background: $cp2

    .cp2
        color: $cp1
.section__content
    z-index: 3

[data-cover="dark"]::before
    background: $s-cover
    opacity: 0.5

[data-cover="light"]::before
    background: $s-cover-light
    opacity: 0.5

.lazy.section--bg
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    opacity: 0
    background-repeat: no-repeat
    background-size: cover
    @include multipleTransition((transform 0.45s ease, opacity 0.25s))

.lazy.section--bg.loaded
    opacity: 1
    @include multipleTransition((transform 0.45s ease, opacity 0.85s))

.contact__address
    margin-bottom: 30px

.section--bg
    background-attachment: initial
    background-repeat: no-repeat
    background-position: top center
    pointer-events: none

+breakpoint(desktop)

+breakpoint(tablet)

    .section__title
        +rem($h3)

    .section__subtitle
        +rem($f-size-ss)
        margin-top: $mg-ss

    .section__header
        margin-bottom: $mg

    .section
        padding-top: 0
        padding-bottom: 0

    .contact__wrapper
        +flex-wrap(wrap)

    .contact
        order: 0
        text-align: center

    .section__content
        max-width: 100%
        padding:
            left: 20px
            right: 20px

    .section--bg
        background-attachment: initial

+breakpoint(mobilewide)
    .section
        // padding-top: $mg
        // padding-bottom: $mg
        padding-left: 20px
        padding-right: 20px

    .t-msw-center
        text-align: center

    .section__content.full
        padding-left: 0
        padding-right: 0

    .section--home
        padding-left: 0
        padding-right: 0
        .section__content
            padding: 0
            margin: 0
        .page__title
            padding: 0
            margin-bottom: 60px
            margin: 0

        .page__logo
            // height: 160px

    .contact
        address
            +rem(18)

            br
                display: none

    .contact__link
        margin: 15px ​0 0
        +rem(18)

        i
            +rem(14)
            margin-right: 8px

+breakpoint(mobile)
    .section
        padding-top: $mg
        padding-bottom: $mg

        ul
            // margin-left: 20px

    .section--home
        .section__content
            padding: 0
            margin: 0
        .page__title
            +rem(28)

// +breakpoint(4k)
// +breakpoint(laptop)
// +breakpoint(desktop)
// +breakpoint(tablet)
// +breakpoint(mobilewide)
// +breakpoint(mobile)
+breakpoint(mobilesmall)

.io-detector
    position: fixed
    height: 1px
    top: 50%
    left: 0
    right: 0
    z-index: 100
    background-color: red
