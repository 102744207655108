
// margin/paddings

$mg-ss: 5px
$mg-s: 15px
$mg: 30px
$mg-m: 45px
$mg-b: 60px
$mg-bb: 80px

// marginesy

.mg-l-auto
    margin-left: auto

.mg-r-auto
    margin-right: auto

.mg-0
    margin: 0 !important

.mg-hor-ss
    margin:
        left: $mg-ss
        right: $mg-ss

.mg-ver-ss
    margin:
        top: $mg-ss
        bottom: $mg-ss

.mg-t-ss
    margin-top: $mg-ss

.mg-b-ss
    margin-bottom: $mg-ss

.mg-l-ss
    margin:
        left: $mg-ss

.mg-r-ss
    margin:
        right: $mg-ss

.mg-hor-s
    margin:
        left: $mg-s
        right: $mg-s

.mg-ver-s
    margin:
        top: $mg-s
        bottom: $mg-s

.mg-t-s
    margin-top: $mg-s

.mg-b-s
    margin-bottom: $mg-s

.mg-l-s
    margin:
        left: $mg-s

.mg-r-s
    margin:
        right: $mg-s

.mg-hor
    margin:
        left: $mg
        right: $mg

.mg-ver
    margin:
        top: $mg
        bottom: $mg

.mg-t
    margin-top: $mg

.mg-b
    margin-bottom: $mg

.mg-l
    margin:
        left: $mg

.mg-r
    margin:
        right: $mg

.mg-hor-m
    margin:
        left: $mg-m
        right: $mg-m

.mg-ver-m
    margin:
        top: $mg-m
        bottom: $mg-m

.mg-t-m
    margin-top: $mg-m

.mg-b-m
    margin-bottom: $mg-m

.mg-l-m
    margin:
        left: $mg-m

.mg-r-m
    margin:
        right: $mg-m

.mg-hor-b
    margin:
        left: $mg-b
        right: $mg-b

.mg-ver-b
    margin:
        top: $mg-b
        bottom: $mg-b

.mg-t-b
    margin-top: $mg-b

.mg-b-b
    margin-bottom: $mg-b

.mg-hor-bb
    margin:
        left: $mg-bb
        right: $mg-bb

.mg-ver-bb
    margin:
        top: $mg-bb
        bottom: $mg-bb

.mg-t-bb
    margin-top: $mg-bb

.mg-b-bb
    margin-bottom: $mg-bb

.mg-l-bb
    margin:
        left: $mg-bb

.mg-r-bb
    margin:
        right: $mg-bb

.mg-b-0
    margin-bottom: 0

.mg-t-0
    margin-top: 0

// paddings

.pd-0
    padding: 0 !important

.pd-hor-ss
    padding:
        left: $mg-ss
        right: $mg-ss

.pd-ver-ss
    padding:
        top: $mg-ss
        bottom: $mg-ss

.pd-t-ss
    padding-top: $mg-ss

.pd-b-ss
    padding-bottom: $mg-ss

.pd-l-ss
    padding:
        left: $mg-ss

.pd-r-ss
    padding:
        right: $mg-ss

.pd-hor-s
    padding:
        left: $mg-s
        right: $mg-s

.pd-ver-s
    padding:
        top: $mg-s
        bottom: $mg-s

.pd-t-s
    padding-top: $mg-s

.pd-b-s
    padding-bottom: $mg-s

.pd-l-s
    padding:
        left: $mg-s

.pd-r-s
    padding:
        right: $mg-s

.pd-hor
    padding:
        left: $mg
        right: $mg

.pd-ver
    padding:
        top: $mg
        bottom: $mg

.pd-t
    padding-top: $mg

.pd-b
    padding-bottom: $mg

.pd-l
    padding:
        left: $mg

.pd-r
    padding:
        right: $mg

.pd-hor-m
    padding:
        left: $mg-m
        right: $mg-m

.pd-ver-m
    padding:
        top: $mg-m
        bottom: $mg-m

.pd-t-m
    padding-top: $mg-m

.pd-b-m
    padding-bottom: $mg-m

.pd-l-m
    padding:
        left: $mg-m

.pd-r-m
    padding:
        right: $mg-m

.pd-hor-b
    padding:
        left: $mg-b
        right: $mg-b

.pd-ver-b
    padding:
        top: $mg-b
        bottom: $mg-b

.pd-t-b
    padding-top: $mg-b

.pd-b-b
    padding-bottom: $mg-b

.pd-hor-bb
    padding:
        left: $mg-bb
        right: $mg-bb

.pd-ver-bb
    padding:
        top: $mg-bb
        bottom: $mg-bb

.pd-t-bb
    padding-top: $mg-bb

.pd-b-bb
    padding-bottom: $mg-bb

.pd-l-bb
    padding:
        left: $mg-bb

.pd-r-bb
    padding:
        right: $mg-bb

.pd-b-0
    padding-bottom: 0

.pd-t-0
    padding-top: 0
