// custom client styles
iframe
    max-width: 100%

h3, .h3
    font-weight: 500
.f-bolder
    // font-weight: 900

.nav-contact__link
    &:hover
        color: white
        text-decoration: underline

.zombie
    margin: 30px auto

.section__content
    // padding-left: 10%
    // padding-right: 10%
.list.list--big
    // font-size: 24px
    // font-weight: 400
    li
        margin: 10px 0

.magda-before-after
    +flexbox()
    +justify-content(space-between)

.strike
    text-decoration: line-through
.quote
    line-height: 1.4
    position: relative
    background: #eee
    padding: 25px
    font-size: 20px
    font-weight: 400
    border: 2px #ddd dashed
    // font-style: italic

.quote::before
    // position: absolute
    // top: 0
    // left: 0
    // font-size: 144px
    content: open-quote

.quote::after
    content: close-quote

#home-top
    border-bottom: 1px #999 dashed
    padding-bottom: 60px
    padding: 60px 0
    margin: 60px 5%

    h1
        // font-size: 24px

.about-me
    width: 100%
    // margin: 30px 0
    +flexbox()
.about-me__photo
    margin-right: 30px
    max-height: 300px
.about-me__info
    width: 100%

.b-bottom
    border-bottom: 1px black dashed

.b-top
    border-top: 1px black dashed

// +breakpoint(4k)
// +breakpoint(laptop)
// +breakpoint(desktop)
// +breakpoint(tablet)
+breakpoint(tablet)

    .rwd-no-br
        display: none

    #home-top h1
        // font-size: 16px

    .quote
        font-size: 22px

    .rwd-space
        display: block
        height: 20px

    .rwd-br
        display: block

    .section.section--full
        padding-top: 0
        padding-bottom: 0
        margin-top: 30px
        margin-bottom: 30px

    .about-me
        margin: 30px 0
        padding: 0
        padding-top: 30px
        padding-bottom: 30px
        &.me--michal
            flex-direction: column-reverse
    .about-me__photo
        margin: 0 auto

    .about-me__info
        padding: 0
        text-align: center

    #home-top
        margin-top: 15px
        padding-bottom: 30px
        margin-bottom: 15px

        h1
            margin-top: 30px

    #s2
        .mg-b-bb
            margin-bottom: 0
        .mg-t-bb
            margin-top: 45px

    .zombie
        margin: 30px auto
+breakpoint(mobilewide)

    #home-top
        margin-top: 30px
        padding-top: 0

        h1
            padding: 0
            text-align: left
    .zombie
        margin: 0 auto
    ul.list
        font-size: 15px
        margin-left: 15px
        // padding-left: 0
        &.list--big
            font-size: 20px
            margin-left: 30px
        // font-weight: 400
        li
            margin: 10px 0

    .home-text
        font-size: 15px

    .quote
        font-size: 16px
        padding: 15px
        margin: 30px 0
        font-style: normal

    .mg-t-b,
    .mg-t-bb
        margin-top: 30px

    .pd-b-b,
    .pd-b-bb
        padding-bottom: 30px

    .pd-t-b,
    .pd-t-bb
        padding-top: 30px

    small.h3
        font-size: 18px
