input, select, textarea
    max-width: 100%
    font:
        family: $f-family

    &:focus
        outline: none

button::-moz-focus-inner,
input::-moz-focus-inner
    border: 0
    padding: 0

form
    max-width: 540px

.checkbox__content
    opacity: 0.5
    line-height: 1.4

label
    // display: block

// input[type="text"],
// input[type="email"]
//     display: block
//     width: 100%
//     height: 45px
//     line-height: 44px
//     background: white
//     border: 1px #eee solid
//     padding: 7px 15px

.form-apply
    position: relative
input[type="text"],
input[type="email"],
input[type="date"],
select,
textarea
    display: block
    width: 100%
    margin: 8px 0
    padding: 12px 15px
    border: 1px rgb(161 161 161 / 42%) solid
    font-family: $f-family
    border-radius: $radius
    +rem(14)
    color: white
    +alpha(white, -1)
    +multipleTransition((transform $speed 0.8s, opacity $speed 0.5s, background 0.45s, border $speed))
    color: $c-black
    &:invalid
        background: initial

    &:focus
        +alpha(white, -0.2)
        border-color: +op($cp2, -0.6)
        color: $c-black


    [data-cover="dark"] &
        padding: 12px 15px
        border: 1px rgb(238 238 238 / 42%) solid
        background: rgb(0 0 0 / 32%)
        font-family: $f-family
        border-radius: $radius
        +rem(14)
        color: white
        +alpha($cp1, -1)

        &:focus
            +alpha(black, -0.6)
            border-color: +op($cp4, -0.6)
            color: white

textarea
    min-height: 150px



.checkbox
    +rem(12)
    text-align: left


.checkbox__input
    vertical-align: text-top


+breakpoint(tablet)
    .form-row.row--action
        text-align: center
        margin: 30px 0