
// NAVI

.nav-top
    top: 0
    left: 0
    width: 100%
    z-index: 10
    +multipleTransition((height $speed, transform 0.25s ease-in-out 0.45s, box-shadow 0.25s ease-in-out))

.nav-top__list
    +flex(1)
    // +multipleTransition((opacity  0.4s ease 0.65s, transform 0.5s ease-in-out 0.65s, width 0.2s ease-in-out 0.65s))

.nav-contact
    padding-right: $content-padding
    +multipleTransition((opacity  0.4s ease , transform 0.5s ease-in-out , width 0.2s ease-in-out ))

    .menu--active &
        opacity: 0
        +multipleTransition((opacity  0.4s ease , transform 0.5s ease-in-out , width 0.2s ease-in-out ))

.nav-top__link
    display: block
    padding: $nav-link-padding
    color: $nav-link-color
    font-weight: $nav-link-weight
    +rem($nav-link-size)
    background: $nav-link-bg
    text-transform: $nav-link-transform
    border: $nav-link-border
    letter-spacing: $nav-link-letter-spacing
    border-radius: $radius
    &:hover
        background: $nav-link-bg-hover
        border: $nav-link-border-hover
        color: $nav-link-color-hover

    &.item--active
        border: 1px $cp1 solid
        color: $nav-link-color-active
        background: $nav-link-bg-active
        border: $nav-link-border-active
        // font-weight: 400

.nav-contact
    text-transform: uppercase
    font-weight: 500
    width: 100%
    letter-spacing: 1px
    +rem(15)
    +flexbox()
    +align-items(center)

    ul, li
        +flexbox()
        +align-items(center)

    i
        color: $cp1

.nav-contact__label
    padding-right: 10px
    color: $cp1
    font-weight: $f-bold

.nav-contact__phone
    margin-right: 20px
    +flexbox()

.nav-contact__email
    +flexbox()

.nav-contact__link
    +flexbox()
    +align-items(center)
    color: $nav-contact-color
    text-transform: $nav-contact-transform
    letter-spacing: 0
    display: block
    white-space: nowrap
    margin-left: 10px
    letter-spacing: 0
    +rem($nav-contact-f-size)
    // font-weight: 500
    // margin: 0 15px

    &:hover
        color: $nav-contact-color-hover
        span
            // text-decoration: underline

    i
        // background: $cp4
        padding: 6px
        border-radius: $radius
        color: $cp1
        margin-right: 5px
        +rem($nav-contact-ico-size)
        +multipleTransition((color 0.2s, font-size 0.2s))
    &:hover
        i
            // +rem(22)
            // color: white

    .page-header
        +translate3d((0, 0, 0))

// RWD NAVI

.menu-burger__bg
    display: none
    position: fixed
    top: -2px
    padding-top: 8px
    left: 15px
    left: initial
    right: -2px
    width: 50px
    height: 55px
    color: white
    border-radius: 0 0 0 5px
    background: $nav-burger-bg
    // +makeGradient(#df5900, #df5900, $color-orange)
    z-index: 999
    // border: 1px rgb(173 135 0 / 51%) solid
    box-shadow: -2px 1px 6px rgba(0, 0, 0, 0.22)
    +multipleTransition((transform 250ms ease-in-out 350ms, right 0.65s ease-in-out))

    &::before2
        position: absolute
        content: ''
        top: 0
        right: 0
        width: 3000px
        height: 5px
        background: $cp1

    .menu--active &
        background: $c-black
        background: #af0b0b
        +translate3d((0, 375px, 0))
        +multipleTransition((transform 250ms ease-in-out))

        span
            background: white

    &.modal--active
        right: -100%
        +multipleTransition((transform 250ms ease-in-out 250ms, right $speed))

.menu-burger__btn
    +flexbox()
    position: relative
    margin: auto
    width: 30px
    height: 28px
    cursor: pointer

    span
        display: block
        position: absolute
        left: 0
        height: 4px
        width: 100%
        background: $nav-burger-color
        border-radius: $radius
        opacity: 1
        +multipleTransition((transform 0.2s ease-in-out, width 0.25s, top 0.25s, left 0.25s, opacity 0.25s))

        &:nth-child(1)
            top: 0
            .menu--active &
                opacity: 0

        &:nth-child(2)
            top: 9px
            .menu--active &
                +rotate(45)

        &:nth-child(3)
            top: 9px
            .menu--active &
                +rotate(-45)

        &:nth-child(4)
            top: 18px
            .menu--active &
                opacity: 0

// DROPDOWN MENU
.logo-menu
    padding: 10px 20px
    max-width: 100%
    height: auto
    margin: 30px auto
    display: none
.nav-top__item
    position: relative

    > i
        position: absolute
        top: 8px
        right: 8px
    &:hover > .nav-top__list[data-dropmenu]
        display: block

.nav-top__list[data-dropmenu]
    display: none
    position: absolute
    top: 100%
    left: 0
    width: 210px
    z-index: 11
    background: $cp1
    border-radius: 0 5px 5px 5px
    border-radius: 0

    .nav-top__item
        border-radius: 0

        &:first-child
            .nav-top__link
                border-radius: 0 5px 0 0
                border-radius: 0
        &:last-child
            .nav-top__link
                border-radius: 0 0 5px 5px
                border-radius: 0

    .nav-top__link
        background: none
        border: 0
        padding: 0
        margin: 0
        padding: 5px 25px 5px 35px
        border-top: 1px rgb(255 255 255 / 11%) solid
        border-radius: 0px
        text-transform: none
        color: white
        font-weight: 400
        position: relative

        &::before
            position: absolute
            content: ''
            top: 0
            left: 0
            width: 5px
            height: 5px
            background: $cp3
            top: 12px
            left: 21px
            width: 5px
            height: 5px
            background: #936037

        &:hover
            color: $cp1
            background: $cp2
            font-weight: 400

.nav-top__list[data-dropmenu="2"]
    // position: relative
    left: 100%
    top: 0
    background: #321c0d

    .nav-top__item

        // padding-left: 15px

.nav-top__item.item--parent
    &:hover
        background: $cp1
        color: white
        border-bottom: 0
        // border-radius: 5px 5px 0 0

        > .nav-top__link
            color: white

.nav-top__list
    display: none

.nav-top > .nav-top__list
    +flexbox()

    // +translate3d((0, -100%, 0))
    .body--animation-start &
        z-index: 100
        margin-top: 0
        +translate3d((0, 0, 0))
        +multipleTransition((transform $speed, height $speed, margin $speed, box-Shadow $speed))

.rwd-contact-container
    +flexbox()

+breakpoint(desktop)

    .logo-menu
        display: block

    .nav-top

        overflow-x: hidden

        .menu--active &
            opacity: 1
            +translate3d((0,0,0))
            +multipleTransition((box-Shadow $speed, transform 0.25s ease-in-out, background 0.25s ease-in-out 0.32s))

        .fs-info
            right: 30px

            .fs-info__text
                // right: 20px

    .body--animation-start .nav-top__list
        position: static
        left: 0
        width: 100%
        // padding: 2% 15%
        +flex-wrap(wrap)

    .nav-top__list[data-dropmenu]
        display: none

    .nav-top__list[data-dropmenu]
        .nav-top__link
            padding: 10px 10px 10px 40px
            text-align: left

    .nav-top__item
        position: static
        width: 100%
        text-align: center
        margin: 0
        margin-top: 0
        margin-bottom: 1px
        // +translate3d((-100%,0,0))
        +multipleTransition((opacity $speed, transform2 0.65s ease-in-out, background2 0.25s ease-in-out))

        &:nth-child(2n+1)
            // +translate3d((100%,0,0))
        &.item--parent
            position: relative
            &:hover
                > .nav-top__link
                    color: $cp1

        .menu--active &
            opacity: 1
            +translate3d((0,0,0))
            +multipleTransition((opacity $speed, transform2 0.65s ease-in-out 0.4s, background2 0.25s ease-in-out))

    .nav-top__link
        pointer-events: auto
        padding: 20px 20px
        border-bottom: 3px transparent solid
        color: $nav-rwd-link-color
        +rem(18)
        font-weight: 400
        background: $nav-rwd-link-bg
        line-height: 1
        // text-transform: none
        font-weight: 500
        border: 0
        border-radius: 0

        &.item--active,
        &:hover
            border: 0
            background: $nav-rwd-link-bg-active
            color: $nav-rwd-link-color-active

    .nav-top__item:hover > .nav-top__list[data-dropmenu]
        display: none

    .nav-top__item.item--parent
        .nav-top__list[data-dropmenu]
            display: none
            text-align: left

    // .menu--active
    .rwd-contact-container
        width: 100%
        display: block
        text-align: center
        margin-bottom: 15px

    .nav-contact__link
        display: block
        // position: absolute
        z-index: 2
        color: $cp2
        z-index: 2222
        bottom: 30px
        left: 0
        width: 100%
        text-align: center
        margin: 0
        padding: 0 30px

        i
            color: $cp2

    .menu-burger__bg
        +flexbox()
        +translate3d((0, -90px, 0))
        +multipleTransition((opacity 0.6s ease, transform $speed))

        .body--animation-start &
            +translate3d((0, 0, 0))
            +multipleTransition((opacity 0.85s, transform 0.85s ease))

// +breakpoint(4k)
// +breakpoint(laptop)
// +breakpoint(desktop)
// +breakpoint(tablet)
+breakpoint(mobilewide)
    .nav-top__link
        font-size: 14px
// +breakpoint(mobile)
+breakpoint(mobile)
    .menu--active .nav-top
        box-shadow: none
