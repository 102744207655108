// most popular 2021 poland


$width_4k: 1980px !default //  upper
    
$width_laptop_max: 1536px !default //  1366x768 && 1536x864
$width_desktop_max: 1280px !default // niewiadomoco
// tablet
$width_tablet_max: 1024px !default // 768x1024
// mobilewide
$width_mobile_wide_max: 640px !default // 360x640, 393x851, 412x915, 375x667
// mobile
$width_mobile_max: 412px !default
$width_mobile_small_max: 360px !default





    
    
// przyjmuje parametry:
// min: desktop | tablet | mobilewide | mobile | {\d}px
// max: {\d}px
=breakpoint($min, $max: 0)

    @if $min == 4k
        @media only screen and (min-width: $width_4k)
            @content
    @else if $min == laptop
        @media only screen and (max-width: $width_laptop_max)
            @content

    @else if $min == desktop
        @media only screen and (max-width: $width_tablet_max)
            @content


    @else if $min == tablet
        @media only screen and (max-width: $width_tablet_max)
            @content

    @else if $min == mobilewide
        @media only screen and (max-width: $width_mobile_wide_max)
            @content

    @else if $min == mobile
        @media only screen and (max-width: $width_mobile_max)
            @content

    @else if $min == mobilesmall
        @media only screen and (max-width: $width_mobile_small_max)
            @content

    @else
        @if $min == 0
            @media only screen and (max-width: $max)
                @content
        @else if $max == 0
            @media only screen and (min-width: $min)
                @content
        @else
            @media only screen and (min-width: $min) and (max-width: $max)
                @content

=breakpointHeight($min, $max: 0)
    @if $min == 0
        @media only screen and (max-height: $max)
            @content
    @else if $max == 0
        @media only screen and (min-height: $min)
            @content
    @else
        @media only screen and (min-height: $min) and (max-height: $max)
            @content



//testfly

// +breakpoint(4k)
//     .page-container
//         background: pink !important
// +breakpoint(laptop)
//     .page-container
//         background: gray !important
// +breakpoint(desktop)
//     .page-container
//         background: yellow !important
// +breakpoint(tablet)
//     .page-container
//         background: green !important
// +breakpoint(mobilewide)
//     .page-container
//         background: orange !important
// +breakpoint(mobile)
//     .page-container
//         background: red !important
// +breakpoint(mobilesmall)
//     .page-container
//         background:  blue !important