$socials: ("fb": 0.65s, "insta": 0.8s, "pint": 0.95s, "twitt": 1.1s)

@each $name, $delay in $socials
    .nav-social__item.item--#{$name}
        -webkit-transition-delay: $delay
        transition-delay: $delay

// @for $i from 1 through 3
// ul:nth-child(3n + #{$i})
//     pointer-events: auto

// .page-header
//     +translate3d((0, -100%, 0))
//     .body--animation-start &
//         z-index: 100
//         margin-top: 0
//         +translate3d((0, 0, 0))
//         +multipleTransition((transform $speed, margin $speed, boxShadow $speed))

.nav-social__item
    opacity: 0
    +translate3d((100px, 0, 0))
    +multipleTransition((opacity $speed 0.45s, transform $speed))

    .body--animation-start &
        opacity: 1
        +translate3d((0, 0, 0))

// .nav-contact__link,
// .contact__link-label
//     opacity: 1
//     +multipleTransition((margin $speed, color $speed, opacity $speed))

//     & ~ .nav-contact__link
//         +multipleTransition((margin $speed, color $speed, opacity $speed))
//     .body--animation-start &
//         opacity: 1

// .page__logo
//     opacity: 0
//     +multipleTransition((opacity $speed, transform $speed))

//     .body--animation-start &
//         opacity: 1
//         +multipleTransition((opacity $speed, transform $speed, margin $speed))

.logo__man
    +translate3d((0, 30px, 0))
    +multipleTransition((opacity $speed, transform $speed))

    .body--animation-start &
        +translate3d((0, 0, 0))

.logo__txt
    +translate3d((0, -30px, 0))
    +multipleTransition((opacity $speed, transform $speed))

    .body--animation-start &
        +translate3d((0, 3px, 0))

.page__title
    opacity: 0
    +multipleTransition((opacity $speed, transform $speed))

    .body--animation-start &
        opacity: 1

// home

// .home__title
//     opacity: 0
//     +translate3d((150px, 0, 0))
//     +multipleTransition((opacity 0.65s ease 0.9s, transform 0.65s ease-in-out 0.9s))

//     .body--animation-start &
//         opacity: 1
//         +translate3d((0, 0, 0))
//         +multipleTransition((opacity 0.65s ease 0.9s, transform 0.65s ease-in-out 0.9s, margin 0.75s ease-in-out))

// .home__content
//     .body--animation-start &
//         opacity: 1
//         +multipleTransition((opacity 0.35s, transform 0.65s ease-in-out 0.9s, margin 0.65s ease-in-out))    

// .home__subtitle
//     opacity: 0
//     +translate3d((-150px, 0, 0))
//     +multipleTransition((opacity 0.65s ease 0.9s, transform 0.65s ease-in-out 0.9s))

//     .body--animation-start &
//         opacity: 1
//         +translate3d((0, 0, 0))
//         +multipleTransition((opacity 0.65s ease 1.1s, transform 0.65s ease-in-out 1.1s, margin 0.75s ease-in-out))

// .home__btn
//     opacity: 0
//     +translate3d((50px, 0, 0))
//     +multipleTransition((opacity 0.65s ease 0.9s, transform 0.65s ease-in-out 0.9s, background 0.35s ease-in-out, color 0.35s ease-in-out))

//     .body--animation-start &
//         opacity: 1
//         +translate3d((0, 0, 0))
//         +multipleTransition((opacity 0.65s ease 1.4s, transform 0.65s ease-in-out 1.4s, background 0.35s ease-in-out, color 0.35s ease-in-out))
