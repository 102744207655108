
.page-header
    height: $header-height
    position: $header-position
    top: 0
    left: 0
    width: 100%
    padding: 0 20px
    z-index: $header-index
    background: $header-bg
    z-index: 155
    border-bottom: 2px white solid
    box-shadow: 0 -10px 121px #000
    +flexbox()
    +justify-content(space-between)
    +align-items(center)
    &::after
        opacity: 0

    .header--scroll &
        border-bottom: 1px $c-border solid
        border-bottom: 1px transparent solid
        +translate3d((0, 0, 0))
        // +multipleTransition((transform $speed 0.8s, background 0.45s, top $speed))

        &:hover
            background: rgb(255 255 255 / 100%)

        &::after
            opacity: 0.3
            top: 0
            -webkit-transition: opacity 0.9s cubic-bezier(0.165, 0.84, 0.44, 1), top $speed
            transition: opacity 0.9s cubic-bezier(0.165, 0.84, 0.44, 1), top $speed

.page-header__container
    position: relative
    width: 100%
    height: 100%
    +flexbox()
    +justify-content(space-between)
    +align-items(center)

.page__logo
    position: relative
    text-align: center
    max-width: 80%
    margin: 0 auto
    img
        display: block
        margin: 0 auto
        max-height: 300px

.page__title
    position: static
    bottom: 100px
    left: 0
    width: 100%
    text-align: center
    z-index: 2
    margin-top: 30px
    font-weight: 700
    text-shadow: 1px 1px 1px black
    padding:
        left: 30px
        right: 30px

.mobile--adv
    display: none
    text-align: center
    margin: auto
    width: 100%
    padding: 0 30px
    position: relative
    z-index: 1
    color: $nav-rwd-color
    margin-bottom: 30px

    h1
        padding: 20px
        +rem(24)

        strong
            white-space: nowrap
            +rem(26)

+breakpoint(desktop)

    .page-header__container
        pointer-events: none
        overflow: auto
        background: $nav-rwd-bg
        color: $nav-rwd-bg
        +flex-wrap(wrap)
        +multipleTransition((height $speed,box-Shadow $speed,  transform 0.45s ease-in-out, background 0.25s ease-in-out 0.32s, opacity 0.25s))
        +translate3d((-100%,0,0))

        .menu--active &
            // height: 100vh
            opacity: 1
            box-shadow: 0 -10px 121px #000
            +translate3d((0,0,0))
            // background: $cp1
            pointer-events: auto
            +multipleTransition((box-Shadow $speed, transform 0.45s ease-in-out, background 0.25s ease-in-out 0.32s))

    .page-header
        display: block
        position: fixed
        top: 0
        left: 0
        width: 100%
        height: 100%
        overflow: auto
        box-shadow: none
        border: 0
        overflow: hidden
        pointer-events: none
        +translate3d((0,0,0))

        &::before
            position: fixed
            content: ''
            top: 0
            left: 0
            height: 100%
            width: 100%
            background: rgb(0 0 0 / 72%)
            +translate3d((100%,0,0))
            +multipleTransition((transform 0.45s, height $speed, margin $speed, box-Shadow $speed))
            .menu--active &
                // box-shadow: 0 -10px 121px #000
                +translate3d((0,0,0))

        .menu--active &
            pointer-events: auto
            // box-shadow: 0 -10px 121px #000
            +translate3d((0,0,0))
    .mobile--adv
        display: block

    .rwd-contact-link.nav-contact__link,
    .nav-contact__link.link--tel
        color: $cp1 !important
        i
            color: $cp1 !important
    .page-header__container
        max-width: 500px

    .page-header
        height: 100%
        background: transparent
        padding: 0
        opacity: 1
        +multipleTransition((boxShadow $speed))

        .menu--active2 &
            opacity: 1

        .fs-info
            margin-top: -50px

+breakpoint(tablet)
    .page__logo
        max-width: 100%
        margin-left: 0 !important
    .page__title
        line-height: 1
        +rem(36)

    .page__title br
        // display: none

// +breakpoint(4k)
// +breakpoint(laptop)
// +breakpoint(desktop)
// +breakpoint(tablet)
+breakpoint(mobilewide)
    .page-header__container
        max-width: 100%
// +breakpoint(mobile)
// +breakpoint(mobilesmall)
