html
  // font-size: 100%
  // -webkit-text-size-adjust: 100%
  // -ms-text-size-adjust: 100%
  // font-family: sans-serif
  // color: #222

button, input, select, textarea
  // font-family: sans-serif
  // color: #222

body
  margin: 0
  // font-size: 1em
  // line-height: 1.4

::-moz-selection
  background: #000000
  color: #fff
  text-shadow: none

::selection
  background: #000000
  color: #fff
  text-shadow: none

*
  box-sizing: border-box
  -webkit-box-sizing: border-box
  -moz-box-sizing: border-box
  -o-box-sizing: border-box
  -ms-box-sizing: border-box
img
  border: 0
  -ms-interpolation-mode: bicubic
  vertical-align: middle
  max-width: 100%
  max-height: 100%

form
  margin: 0


img:not([src])
  visibility: hidden
figure
  margin: 0
  padding: 0


fieldset
  border: 0
  margin: 0
  padding: 0

address
  font-style: normal

legend
  border: 0
  *margin-left: -7px
  padding: 0
  white-space: normal

button, input, select, textarea
  font-size: 100%
  margin: 0
  vertical-align: baseline
  *vertical-align: middle

button, input
  line-height: normal

button::-moz-focus-inner, input::-moz-focus-inner
  border: 0
  padding: 0

textarea
  overflow: auto
  vertical-align: top
  resize: vertical


input:invalid, textarea:invalid
  background-color: #f0dddd

input:focus
  outline: none

input[type=submit], label, select, .pointer
  cursor: pointer

p label
  width: 100px
  float: left
  margin-right: 10px
  text-align: rightright

ul
  padding: 0
  margin: 0
  list-style: none

table
  border-collapse: collapse
  border-spacing: 0

td
  vertical-align: top

.chromeframe
  margin: 0.2em 0
  background: #ccc
  color: black
  padding: 0.2em 0



address, dl, ol, ul
  margin: 0
  padding: 0
  font-style: normal