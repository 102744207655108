
.btn
    display: inline-block
    padding: 10px 25px
    border-radius: $radius
    color: $cp1
    font-family: $f-family
    font-weight: 500
    font-style: normal
    cursor: pointer
    +rem(16)
    line-height: 1
    text-transform: uppercase
    +multipleTransition(($a-link))

    &[disabled]
        background: gray
        border-color: gray
        opacity: 0.5
        cursor: default
        // pointer-events: none

.btn--small
    padding: 8px 16px
    +rem(14)

.btn--primary
    @extend .btn
    font-weight: $btn-primary-weight
    color: $btn-primary-color
    background: $btn-primary-bg
    border: 2px $btn-primary-border solid
    // border: 0

    &:hover
        color: $btn-primary-color-hover
        background: $btn-primary-bg-hover
        border-color: $btn-primary-border-hover

    [data-cover="dark"] &
        &:hover
            background: $btn-primary-bg-hover
            border-color: $btn-primary-border-hover
            color: $btn-primary-color-hover

.btn--secondary
    @extend .btn
    color: $btn-secondary-color
    background: $btn-secondary-bg
    border: 2px $btn-secondary-border solid

    &:hover
        background: $btn-secondary-bg-hover
        border: 2px $btn-secondary-border solid
        color: $btn-secondary-color
        +opacity(0.5)

[data-toggle]
    display: none

    > p:first-child
        margin-top: 0

.js__toggle
    position: relative
    padding-right: 20px

    &::after
        +icon("\f102")
        margin-left: 12px
        +rotate(180)

    &.active
        background: $c-black
        border-color: $c-black
        &::after
            +rotate(0)
