
.nav-social
    position: fixed
    top: 250px
    right: 0
    padding: 0
    z-index: 10

    li
        margin-bottom: 5px
.nav-social__link
    color: black
    background: $nav-social-bg
    color: $nav-social-color
    // width: 42px
    // height: 42px
    margin-top: 2px
    text-align: center
    border-radius: $radius 0 0 $radius
    // box-shadow: 1px 1px 0px black
    +flexbox()
    +align-items(center)
    +justify-content(center)

    i
        padding: 8px
        +rem(32)

    &:hover
        background: $nav-social-bg-hover
        color: $nav-social-color-hover

        img
            filter: invert(0)

.nav-top__item
    margin: 0 0.5%
    +flex-shrink(0)

// +breakpoint(4k)
// +breakpoint(laptop)
// +breakpoint(desktop)
// +breakpoint(tablet)
+breakpoint(tablet)
    .nav-social
        top: initial
        bottom: 80px
        // display: none
        +translate3d((100%,0,0))
        // height: 100vh
        opacity: 1
        z-index: 111
        // background: $cp1
        +multipleTransition((height $speed, transform 0.25s ease-in-out, background 0.25s ease-in-out 0.32s))

        .menu--active2 &
            +translate3d((0,0,0))
// +breakpoint(mobile)
// +breakpoint(mobilesmall)
